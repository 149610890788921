
.txtDashboard {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom: 50px;
    margin-top: 30px;
  }
    .txtDashboard h2 {
        margin:0px;
    }
  span.customername {
    margin-top: -60px;
    color: #00f;
  }
    @media screen and (max-width:767px) {
        .qr--scanner > div{
            padding: 0 !important;
            min-height:250px !important;
        }

        .qr--scanner video{
            min-height: 200px !important
        }
        .txtDashboard h2 
    {
        font-size: 16px;
    }
    span.customername {
        font-size: 13px;
    }
        .txtDashboard
        {
            margin-bottom:20px;
        }
  }

  .QRCode.QrCode-new {
    padding:0;
    border:none;
    margin-left: auto;
}
.QrReader {
  max-width: 500px;
  margin: 0 auto 20px;
  border-radius: 20px;
  overflow: hidden;
}

.QRCode.QrCode-new {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  text-align: left;
}

.QRCode.QrCode-new div {
  width: 100%;
}
  
  
  

  
  /* Popup style */
  .popup-box {
    position: fixed;
    /*background: #00000050;*/
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 20%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - -20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% -   30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  
  section.QrScanner {
    padding-top: 30px;
    padding-right: 0px;
    display: flex;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  
  .QrScanner section div {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 15px solid rgba(0, 0, 0, 0.3) !important;
    box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 1px inset !important;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .QrScanner section {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
  
  @media screen and (min-width:1025px){
  
    .box {
        position: relative;
        width: 20%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - -20px);
        background: #fff;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #343a40;
        overflow: auto;
    }
    .close-icon {
        content: 'X';
        cursor: pointer;
        position: fixed;
        right: calc(15% - -380px);
        top: calc(100vh - 85vh - -20px);
        background: #343a40;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #343a40;
        font-size: 20px;
        color:#fff;
    }
  
  
    section.QrScanner {
        padding-top: 30px;
        padding-right: 0px;
        display: flex;
        padding-bottom: 10px;
        padding-left: 0px;
    }
  
    .QrScanner section div {
        top: 0px;
        left: 0px;
        z-index: 1;
        box-sizing: border-box;
        border: 15px solid rgba(0, 0, 0, 0.3) !important;
        box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 1px inset !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
  
    .QrScanner section {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 100%;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .box {
        position: relative;
        width: 45%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - -20px);
        background: #fff;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #343a40;
        overflow: auto;
    }
  
    .close-icon {
        content: 'X';
        cursor: pointer;
        position: fixed;
        right: calc(15% - -97px);
        top: calc(100vh - 85vh - -20px);
        background: #343a40;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #343a40;
        font-size: 20px;
        color: #fff;
    }
  
  
    section.QrScanner {
        padding-top: 30px;
        padding-right: 0px;
        display: flex;
        padding-bottom: 10px;
        padding-left: 0px;
    }
  
    .QrScanner section div {
        top: 0px;
        left: 0px;
        z-index: 1;
        box-sizing: border-box;
        border: 15px solid rgba(0, 0, 0, 0.3) !important;
        box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 1px inset !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
  
    .QrScanner section {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 100%;
    }
  }
  
  
  @media screen and (max-width:600px) {
  
    .box {
        position: relative;
        width: 60%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - -20px);
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #343a40;
        overflow: auto;
    }
  
    .close-icon {
        content: 'X';
        cursor: pointer;
        position: fixed;
        right: calc(15% - -17px);
        top: calc(100vh - 85vh - -19px);
        background: #343a40;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #343a40;
        font-size: 20px;
        color: #fff;
    }
  
  
    section.QrScanner {
        padding-top: 30px;
        padding-right: 0px;
        display: flex;
        padding-bottom: 10px;
        padding-left: 0px;
    }
  
    .QrScanner section div {
        top: 0px;
        left: 0px;
        z-index: 1;
        box-sizing: border-box;
        border: 40px solid rgba(0, 0, 0, 0.3) !important;
        box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 1px inset !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
  
    .QrScanner section {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 100%;
    }
  }




  .QRCode {
    display: inline-flex;
    padding: 30px;
    border: 2px dotted #ccc;
    align-items: center;
    border-radius: 5px;
}
/* .QRCode strong
{
font-size:30px;
margin-left:10px;
} */
.wkMOdal .w650 
{
max-width:650px;
}
.wkMOdal .form-group label {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight:500;
	color: #737791;
}

.wkMOdal .form-group select {
    font-size: 14px;
    line-height: 17px;
    color: #9CA3AF;
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    height: 35px;
}
.QrDetail {
    padding: 0;
    list-style-type: none;
    background: #eeeeee36;
	margin:0 -5px;
    padding: 15px;
    border-radius: 10px;
	margin-bottom:30px;
}
.QrDetail li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.QrDetail li label {
    font-weight: 600;
    margin: 0;
}
.QrDetail li:last-child {
    margin: 0;
    border: none;
    padding: 0;
}
.QrImgs {
    width: 25%;
    margin-bottom: 10px;
    padding: 0 5px;
}
.boxImages {
    display: flex;
    flex-wrap: wrap;
}
.content-area .form-group label 
{
font-weight:600;
}
.location {
    display: flex;
    flex-wrap: wrap;
	background: #eeeeee36;
    padding: 20px;
    list-style-type: none;
	justify-content:space-around;
}

.location li label 
{
font-weight:600;
}

.qr--scanner > div {
  padding: 0 !important;
    min-height: 380px;
}
.qr--scanner video {
      overflow: hidden;
      position: relative;
      min-height: 380px;
  }

  /* Upload */
.uploadPicturesWrapper div {
  justify-content: flex-start !important;
  gap: 10px !important;
 
}
/*
.uploadPictureContainer {
  width: 200px !important;
  height: auto !important;
  margin: 2% 5% !important;
  padding: 10px !important;
}

.uploadPictureContainer img {
  height: 200px !important;
}
 */

.qrBtn .fileContainer {
  padding: 0;
  margin: 0;
  display: flex;
}
.qrBtn .fileContainer .chooseFileButton {
  padding: 6px 33px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  border-radius: 3px;
  margin: 0;
  display: inline-block;
  height: 35px;
  border: 1px solid #5D5FEE;
  color: #5D5FEE;
  background: transparent;
}
.qrBtn .fileContainer .chooseFileButton:hover 
{
  background-color: #5D5FEE;
  color: #fff;
}
.qrBtn .fileContainer .deleteImage 
{
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: -9px;
  right: -6px;
  width: 20px;
  height: 20px;
}
.qrBtn .fileContainer 
{
  box-shadow: none;
  background-color: transparent;
}
.uploadPicturesWrapper 
{
  margin-top: 50px;
  margin-bottom: 20px;
}
.qrBtn  .fileContainer .uploadPictureContainer
{
  margin: 0px;
    padding: 5px;
    width: 106px;
    justify-content: center !important;
    align-items: center !important;
    min-height: 82px;
}
    .qrBtn .fileContainer .uploadPictureContainer + .uploadPictureContainer 
    {
       margin-left:0px;
    }
    .qrBtn .fileContainer .uploadPictureContainer img {
        margin: 0 auto;
        width: auto !important;
        height: fit-content;
        max-height: 66px;
    }

/* Webcam */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlxdr.ttf) format('truetype');
  }
  @font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr.ttf) format('truetype');
  }
  .home-container {
  box-sizing: border-box;
  /* background-color: rgb(250, 193, 193); */
  background: #FBD3E9;  /* fallback for old browsers */
  background: linear-gradient(to left, #FBD3E9, #0e6e02);
  margin: 0;
  min-height:80vh;
  padding:60px 0;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  }
  .home-container .container {
  max-width:60vw;
  margin: 0 auto;
  display: flex;
  padding: 0;
  background-color: white;
  border-left: 1px solid white;
  border-radius: 8px 0 0 8px;
  }
  .home-container .container h1 {
  font-size: 40px;
  color:#0e6e02;
  font-weight:600;
  animation: fadein 3s;
  margin-bottom:20px;
  }
  .home-container .container .text
  {
  width: 90%;
  padding:30px 0;
  text-align: center;
  margin:0 auto;
  }
  .home-container form {
  padding: 20px 0;
  position: relative;
  z-index: 2;
  width: 70%;
  margin:0 auto;
  }
  .home-container form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid #0e6e02;
  width: 55%;
  border-radius: 3px;
  padding: 10px 15px;
  margin:10px auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #0e6e02;
  transition-duration: 0.25s;
  }
  .home-container form input:hover {
  background-color: #0e6e02;
  color:#FBD3E9;
  }
  .home-container form input:focus {
  background-color:#0e6e02;
  width: 300px;
  color:#FBD3E9;
  }
  .home-container form ::placeholder
  {
  color:#0e6e02;
  }
  .home-container form input:hover::placeholder
  {
  color:white;
  }
  .home-container form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background-color: #0e6e02;
  border: 0;
  padding: 10px 15px;
  color: #FBD3E9;
  border-radius: 3px;
  font-size:20px;
  width: 60%;
  cursor: pointer;
  transition-duration: 0.25s;
  }
  .home-container form button:hover {
  border: 1px solid  #0e6e02;
  border-radius: 5px;
  background-color: #f5f7f9;
  color: #0e6e02;
  }
  /* webcam  */
  .home-container .webcam-container
  {
  display: flex;
  flex-direction: column;
  text-align: center;
  width:100%;
  margin:0 auto;
  }
  .home-container .webcam-container .webcam-btn
  {
  margin-top: 10px;
  margin-bottom: 20px;
  width:38%;
  }
  /* Media queries ~ */
  @media screen and (max-width:1000px)
  {
  .home-container .container
  {
  max-width:90vw;
  }
  }
  @media screen and (max-width:678px)
  {
  .login-container
  {
  padding-top: 10%;
  }
  .home-container .container
  {
  max-width: 80vw;
  }
  .home-container form
  {
  width:90%;
  }
  .home-container .loginDesign
  {
  display: none;
  }
  .home-container .container .text
  {
  width: 100%;
  padding: 80px 20px;
  }
  }

.video-section
{
  width: 100%;
}

.img-section {
  margin: 20px 15px;
}
.img--box {
  flex: 0 0 25%;
  padding: 5px;
}
.img--box > span {
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #d73838;
  color: #fff;
  font-size: 8px;
  text-align: center;
  cursor: pointer;
}
.img--box > span:hover {
  background-color: #ad0a0a;
}
.img--box > img {
  max-width: 100%;
  border: solid 1px #9CA3AF;
  padding: 4px;
  border-radius: 3px;
}


@media screen and (max-width:767px)
{
    .btn {
        padding: 6px 12px !important;
        font-size: 12px !important; 
    }
}