.ql-size-10px {
    font-size: 10px;
}

.ql-size-12px {
    font-size: 12px;
}

.ql-size-14px {
    font-size: 14px;
}

.ql-size-16px {
    font-size: 16px;
}

.ql-size-18px {
    font-size: 18px;
}

.ql-size-20px {
    font-size: 20px;
}

.ql-size-24px {
    font-size: 24px;
}

.ql-size-32px {
    font-size: 32px;
}

.ql-size-48px {
    font-size: 48px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: attr(data-value);
}

.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value);
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: attr(data-value);
}

.ql-font-arial {
    font-family: Arial, sans-serif;
}

.ql-font-times-new-roman {
    font-family: 'Times New Roman', serif;
}

.ql-font-courier-new {
    font-family: 'Courier New', monospace;
}

.ql-font-comic-sans {
    font-family: 'Comic Sans MS', cursive;
}

.ql-font-georgia {
    font-family: Georgia, serif;
}

.ql-font-impact {
    font-family: Impact, sans-serif;
}

.ql-font-verdana {
    font-family: Verdana, sans-serif;
}

.ql-font-calibri {
    font-family: 'Calibri', sans-serif;
}

.ql-font-helvetica {
    font-family: 'Helvetica', sans-serif;
}

.ql-font-trebuchet-ms {
    font-family: 'Trebuchet MS', sans-serif;
}

.ql-font-garamond {
    font-family: 'Garamond', serif;
}

.ql-font-tahoma {
    font-family: 'Tahoma', sans-serif;
}

/* Ensure the dropdown items display in their respective font styles */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
    font-family: Arial, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
    font-family: 'Times New Roman', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
    font-family: 'Courier New', monospace;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
    font-family: 'Comic Sans MS', cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
    font-family: Georgia, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="impact"]::before {
    font-family: Impact, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
    font-family: Verdana, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="calibri"]::before {
    font-family: Calibri, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet-ms"]::before {
    font-family: 'Trebuchet MS', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="garamond"]::before {
    font-family: Garamond, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
    font-family: Tahoma, sans-serif;
}

/* Ensure the selected font in the dropdown shows in its respective style */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before {
    font-family: Arial, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before {
    font-family: 'Times New Roman', serif;
}

.ql-snow .ql-picker.ql-font {
    width: 150px; /* Adjust the width as needed */
}
/* Repeat for other fonts in the label */


