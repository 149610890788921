
body {
}

.btnTour {
    background-color: #b97a57;
    color: white;
    padding: 6px 16px;
    border-radius: 3px;
}

.tourPara {
    display: flex;
    justify-content: center;
}

.modal-content {
    position: absolute !important;
    /*margin-top: 38%;*/
    margin-top: 10%
}

.modal-footer {
    justify-content: center !important;
}

.btnCloseTour {
    width: 29%;
    justify-content: center;
    border-radius: 3px;
}
