

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
     padding:10px; 
}

.rdw-editor-toolbar {
    
    border-radius: 4px !important;
    border: 1px solid #D1D5DB !important;
    
}