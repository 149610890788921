.active{
    background-color: blue;
}
.wkAccordian li {
    display: inline-block;
    width: 100%;
}
.wkAccordian ul {
    padding: 0;
   
}

.wkAccordian button {
    padding: 8px 14px;
    background: transparent !important;
    border: none !important;
    color: #737791;
    font-size: 14px;
    line-height: 17px;
}
.wkAccordian  .accordion-body {
    padding: 0;
}
.wkAccordian  .nav-link
{
    width: 100%;
}
.wkAccordian button .nav-icon {
    width: 33px;
}

.wkAccordian .accordion-button::after {
    position: absolute;
    right: 15px;
    height: auto;
    width: auto;
    background: none;
    border: solid #737791;
  border-width: 0 2px 2px 0;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  padding: 3px;
    background-image: none !important;
}
.wkAccordian .accordion-button:not(.collapsed)::after 
{
    transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}