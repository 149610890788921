/* Provide sufficient contrast against white background */

a {
    color: #0366d6;
}

body a {
    color: #b97a57;
}

    body a:hover {
        color: #905738;
    }

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.card--height {
    height: calc(100vh - 150px);
}

/********QR Detail***********/
.Viewer-table.table-responsive table tbody tr td:first-child {
   height:38px;
    width: 40px;
    padding-right: 0;
}


.Viewer-table.table-responsive table thead tr th, .Viewer-table.table-responsive table tbody tr td {
    min-width: 40px;

    
    
}
.Viewer-table.table-responsive table head tr th
{
    white-space: nowrap;
}

.Viewer-table.table-responsive table thead tr th:last-child, .Viewer-table.table-responsive table tbody tr td:last-child, .table-new.table-responsive table thead tr th:last-child, .table-new.table-responsive table tbody tr td:last-child {
    text-align: center;
    min-width: 90px;
    padding-left: 10px;
    padding-right: 10px;
}

.reviewPage .Viewer-table.table-responsive table tbody tr td:nth-child(1) {
    min-width: inherit;
    padding-right: 0;
}

.reviewPage .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.reviewPage .Viewer-table.table-responsive table thead tr th:nth-child(1) {
    min-width: inherit;
    padding: 0 !important;
}

.pagination.detail-pagination {
    padding: 0 24px 20px;
}

    .pagination.detail-pagination .page-link {
        padding: 10px;
        min-width: 40px;
        text-align: center;
        color: #333333;
    }

        .pagination.detail-pagination .page-link:hover {
            background-color: #5D5FEE;
            color: #ffffff;
        }

.multi-filter .filter-btn {
    border: none;
    height: auto;
    padding: 10px;
    border-radius: 3px;
}

    .multi-filter .filter-btn:after {
        display: none;
    }

.multi-filter ul.dropdown-menu.show {
    display: flex;
}

.multi-filter ul.dropdown-menu {
    min-width: 230px;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media(min-width: 480px) {
    .multi-filter ul.dropdown-menu {
        min-width: 300px;
    }
}

@media(min-width: 767px) {
    .multi-filter ul.dropdown-menu {
        min-width: 500px;
    }
}

@media(min-width: 1200px) {
    .multi-filter ul.dropdown-menu {
        min-width: 700px;
    }
}


.multi-filter ul.dropdown-menu li {
    margin-bottom: 15px;
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
}

@media(max-width: 767px) {
    .multi-filter ul.dropdown-menu li {
        margin-bottom: 15px;
        flex: 0 0 100%;
        max-width: 100%;
    }
}


.multi-filter ul.dropdown-menu li h6 {
    color: #000000;
}

.multi-filter ul.dropdown-menu li select {
    max-width: 100% !important;
}

.btn-bordered {
    border: 1px #905738 solid !important;
    color: #905738 !important;
}

    .btn-bordered:hover {
        background-color: #905738 !important;
        color: white !important;
    }

.btn-radio {
    height: 15px;
    width: 15px;
    /*display: inline;*/
    /*padding-right: 40px;*/
    margin-right: 5px;
}

.btn-checkbox {
    height: 15px;
    width: 15px;
}


.required-fields {
    font-size: 15px;
    color: red;
    margin-left: 4px;
    float: right;
    height: 12px;
}

.select-Role .ant-select-selection {
    background: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    color: #292a2c;
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    min-height: 35px;
}

.select-Role .ant-select-selection--multiple .ant-select-selection__placeholder {
    color: #a9a3b7;
    font-size: 14px;
}

.select-Role label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #737791;
    font-weight: 600;
}

.antd--detail-table.table-responsive table tbody tr td:nth-child(10) {
    max-width: 230px;
}

    .antd--detail-table.table-responsive table tbody tr td:nth-child(10) span {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
    }

.pagination-sticky .ant-table-pagination.ant-pagination {
    position: sticky;
    right: 0;
    z-index:9;
}

.trash {
    filter: brightness(0) invert(1);
    margin-right: 3px;
}

.addMedia .qrBtn .fileContainer .chooseFileButton {
    position: absolute;
    top: 0;
    left: 150px;
    height: 41px;
    padding: 6px 25px;
}

.addMedia .qrBtn .fileContainer {
    position: inherit;
}

.addMedia .col-sm-12.text-center {
    position: inherit;
}

.addMedia {
    position: relative;
}

.tableclass {
    max-height: 500px;
}

.reviewPage .Viewer-table.table-responsive table tbody tr td:nth-child(1) {
    min-width: inherit;
    padding-right: 0;
}

.reviewPage .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.reviewPage .Viewer-table.table-responsive table thead tr th:nth-child(1) {
    min-width: inherit;
    padding: 0 !important;
}

@media screen and (max-width:767px) {
    .addMedia .qrBtn .fileContainer .chooseFileButton {
        position: absolute;
        top: 0;
        left: 95px;
        height: 39px;
        padding: 6px 8px;
        font-size: 12px;
    }

    .addMedia .qrBtn .fileContainer .uploadPictureContainer {
        flex: inherit;
    }

    .head-list {
        font-size: 21px !important;
    }
}

.main-box {
    margin: 0 0 30px;
}

.head-list {
    font-size: 25px;
    display: block;
    text-transform: capitalize;
    text-align: left;
    line-height: normal;
    margin: 0 auto 25px;
    max-width: 720px;
    padding: 0 0 12px;
    border-bottom: 1px solid #e9e9e9;
    font-weight: 600;
}

.filename {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    color: #000;
    font-weight: 400;
    margin-left: 20px;
}

.checkbox-th {
    width: 20px;
    padding-right: 0 !important;
    padding-left: 10px !important;
}

.imgbox {
    width: 106px;
    height: 82px;
    margin-right: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
    border: 1px solid #d0dbe4;
    position: relative;
    background: #edf2f6;
}

.crossBtn {
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    position: absolute;
    top: -9px;
    right: -6px;
    width: 20px;
    height: 20px;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.imgbox .thumb {
    max-height: 66px;
    height: auto;
    width: auto;
}

@media screen and (max-width: 1000px) {
    .tableclass.table-responsive table {
        width: 100% !important;
    }
}

.logo img {
    height: 30px;
}

.two-line-text {
    display: inline-block;
    max-width: 1000px; /* Adjust width as needed */

    white-space: normal;
}


.layoutSidenav .layoutSidenav_nav {
    height: 100vh;
    position: fixed !important;
    display: flex;
    flex-direction: column;
    z-index: 222;
}

.header-menu-container {
    flex-grow: 1;
    overflow: auto;
}

.table-responsive table tbody tr td {
    max-width: 400px;
    word-break: break-word;
}

.reviewPage .multi-filter ul.dropdown-menu {
    min-width: 552px;
}

@media screen and (max-width: 1200px) {
    .reviewPage .multi-filter ul.dropdown-menu {
        min-width: 400px;
    }
    .multiple-danger-text {
        position: absolute;
        left: -34px;
        bottom: 5px;
    }
}
    @media screen and (max-width: 560px) {
        .reviewPage .multi-filter ul.dropdown-menu {
            min-width: 280px;
            left: -110px !important;
            transform: none !important;
            top: 50px !important;
        }
    }

    .reviewPage .multi-filter ul.dropdown-menu .searchbtQR .form-control {
        max-width: 200px;
    }

    .reviewPage .multi-filter ul.dropdown-menu .searchbtQR .form-label {
        white-space: nowrap;
    }

    .header-section .header-dropdown .dropdown-menu {
        box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
        padding: 20px;
        border-radius: 0.475rem;
    }

    .device-type .ant-table-thead th:nth-child(4) {
        min-width: 240px;
    }

    .device-type.status .ant-table-thead th:nth-child(4) {
        min-width: inherit;
    }

    .backBTN {
        display: flex;
        align-items: center;
    }

        .backBTN img {
            margin-right: 3px;
        }

    .img-mapping {
        width: 38px;
        overflow: hidden;
        height: 38px;
        min-width: 38px;
        border-radius: 5px;
        margin-right: 5px;
    }

    .qr-img-select li {
        display: flex !important;
    }

    .qrDesc {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        align-items: center;
    }

        .qrDesc span {
            min-width: 80px;
            /*text-overflow: ellipsis;
    white-space: nowrap;*/
            /* overflow: hidden;*/
        }

        .qrDesc.content {
            flex-direction: column;
            width: 100%;
        }

            .qrDesc.content h6 {
                text-transform: capitalize;
                margin: 0 0 5px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .qrDesc.content p {
                font-size: 13px;
                margin: 0;
                width: 100%;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: center;
            }

    .thumb.img.add {
        width: 40px !important;
        overflow: inherit !important;
        height: 40px;
    }

    span.required-fields.roles {
        float: none;
        margin-left: -3px;
    }


    @media screen and (max-width: 567px) {
        .thumb.img.add {
            width: 40px !important;
            height: 40px;
        }

        .multi-filter .filter-btn span {
            font-size: 0;
        }

        .multi-filter .filter-btn img {
            margin: 0 !important;
        }
    }

    @media screen and (max-width: 350px) {
        .filter-col {
            font-size: 12px;
        }

        .layout-page .main-section .card-box .card-box-header .btn img {
            height: 20px;
        }

        .layout-page .main-section .card-box .card-box-header .btn {
            padding: 6px 8px !important;
            align-items: center;
            font-size: 10px !important;
        }
    }

    .preview_Link {
        color: #0d6efd !important;
        text-decoration: underline !important;
    }

        .preview_Link_desc {
            color: #0d6efd !important;
            text-decoration: underline !important;
            
        }

.ant-table-column-title {
    white-space: nowrap;
}

    .searchbtQR {
        column-gap: 15px;
    }

    section.count .card:hover {
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
    }

    .content-area .form-group .ant-select-open input {
        height: 28px !important;
        border: none !important;
    }

    .linkQRButtons {
        text-align: center;
    }

    input[type='text'], input[type="password"], input[type="email"], textarea {
        font-size: 16px;
    }

    .ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
        top: -2px !important;
    }

    .QRdescription {
        min-width: 215px !important;
    }

    .Locations {
        min-width: 180px !important;
    }

    .companyName {
        min-width: 165px !important;
    }

    .ellipsss span {
        /*width: 100%;*/
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        max-width: 200px;
    }

    .guide-type {
        min-width: 140px !important;
    }

    .update_mapping {
        min-width: 70px !important;
    }

    .qrcode_mapping {
        min-width: 100px !important;
    }

    .Viewer-table table tbody tr td.preview, .Viewer-table table thead tr th.preview {
        text-align: center !important;
    }

    .thumbnail_list .table-responsive table tbody tr td {
        padding: 7px 10px;
    }

/*.pdf-container{
    width: 70vw;
    height:70vh;
    overflow-y:auto;
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content:center;
}*/

.pdf-containernew{
    margin-top:30px;
}

.footer--logo img {
    max-width: 110px;
}
.footer--logo {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.scanqrcode{
    text-align:center;
}

.multiple_line {
    /*  white-space: pre-wrap;*/
    white-space: pre-line;
}