
*
{
	margin:0px;
	padding:0px;
	box-sizing:border-box;
}
body { margin:0px; font-size:14px; line-height:16px; background:#F8FBFC; font-family: 'Inter', sans-serif;
 color:#000;}
html, body {
}

a:hover{  text-decoration: none; color: #5E8E22;}
a:focus{ outline: none!important;}
a{text-decoration: none; transition:all 0.1s ease-out;  color: #336699;}
button { border: none;}
img { max-width: 100%;}
ul { margin: 0px; padding: 0px; list-style: none;}

.container-fluid {
    padding: 0 30px;
}
.header-section {
	z-index:9;
	position:relative;
    padding: 20px 0;
    background: #FFFFFF;
    box-shadow: 0px 6px 15px rgb(0 0 0 / 5%);
}
.header-section .container-fluid {
    display: flex;
    align-items: center;
    width: 100%;
} 
.header-section .btn-primary {
    background: transparent !important;
    border-radius: 5px;
	border:none !important;
	padding:0px !important;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
	display: flex;
	align-items: center;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    box-shadow: none !important;
}

.header-section .btn-primary:hover 
{
	color:#5D60EF !important;
	background:transparent !important;
}
.header-section .btn-primary:focus, .header-section .btn-primary:active
{
    background:transparent !important;
    box-shadow: none !important;
}

.header-section.userside-page .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    position: fixed !important;
    top: 70px !important;
    bottom: auto;
    border-radius: 0;
    transform: inherit !important;
    padding: 0;
    border-left: 0;
    border-right: 0;
  }
.small-heading 
{
	font-weight: 700;
	font-size: 26px;
	line-height: 31px;
	text-align: center;
	position:relative;
	color: #b97a56;
	margin-bottom:40px;
}
.small-heading::after {
    content: url(../images/wave.png);
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
}
.center-section {
    padding: 52px 0;
    background: url(../images/bg.svg);
    min-height: calc(100vh - 127px);
    background-repeat: no-repeat;
    background-position: 270px -20px;
}
.main-heading 
{
	font-weight: 700;
	font-size: 36px;
	line-height: 44px;
	text-align: center;
	color: #000000;
	margin: 60px auto 40px;
	max-width:740px;
}
.footer {
    background: #FFFFFF;
    padding: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848484;
}

.QRInner {
    border-radius: 10px;
    display: inline-block;
    background-color: #000;
    position: relative;
    border: solid 4px #fff;
    width: 100%;
	margin-bottom: 33px;
    box-shadow: 1px 7px 30px rgb(0 0 0 / 28%);
}
.QRInner .qr--scanner > div  {
	min-height: 291px !important;
}
.QRInner .qr--scanner > div > video {
	min-height: 305px;
}
.QRInner1 {
    background: #FFFFFF;
    box-shadow: 0px 6px 30px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: inline-block;
	position:relative;
    width: 300px;
    height: 300px;
	margin-bottom:45px;
}
.QRInner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 300px;
    background: linear-gradient(180deg, #d4946f 0%, #b97a56 100%);
    left: 21px;
    top: 21px;
    border-radius: 9px;
    z-index: -9;
}
.scan-btn button {
    background: #b97a56;
    border: 2px solid #b97a56;
    border-radius: 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 13px 40px 14px 60px;
    position: relative;
    min-width: 205px;
    height: 50px;
	transition:all 0.3s ease-in-out 0s;
}
.scan-btn button span {
    width: 44px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    left: 1px;
    top: 1px;
	transition:all 0.3s ease-in-out 0s;
}
.scan-btn button span img 
{
	transition:all 0.3s ease-in-out 0s;
	height: 23px;
}
.scan-btn.closebtnn button {
	padding: 13px 53px;
	min-height: auto;
}

.scan-btn button:hover {
    color: #855134;
    background-color: transparent;
    border-color: #855134;
}
.scan-btn button:hover span 
{
	background:#b97a56;
}
.scan-btn button:hover span img 
{
	filter: brightness(0) invert(1);
}

.scan-btn {
	max-width:300px;
	margin:auto;
    text-align: center;
}
.Qr-section {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.QRLeft {
    width: 45%;
}
.QrRight {
    width: 55%;
    display: flex;
    align-items: center;
	padding-left: 51px;
}
.QrRight img {
    margin-right: 40px;
    max-width: 110px;
}
.QrRight h4
{
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #000000;
	margin-bottom:7px;
}
.QrRight p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
	margin:0;
}

.inner-pages 
{
	padding-top:0;
	background-image:none;
}
.Qr-detail {
    background: #FFFFFF;
    border-bottom: 1px solid #EAEAEA;
    padding: 22px 0;
    display: inline-block;
    width: 100%;
	margin-bottom:26px;
	text-align:center;
}
.Qr-detail h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    display: inline-block;
	margin-bottom:0;
    margin-right: 20px;
}
.QrCode {
    background: #EFEFF8;
    display: inline-block;
	text-align:center;
    padding: 9px 45px 9px 45px;
    min-width: 290px;
    position: relative;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
}
.copy-btn {
   position: absolute;
    right: 22px;
    top: 6px;
}
.QRImg {
    margin: 15px 0;
	max-width:520px;
	display:inline-block;
	position:relative;
	border-radius:10px;
    width: 100%;
}

.sticky-parent-thumbnail {
    position: sticky;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #f7f7f7;
    padding: 7px 15px;
    border-bottom: 1px #eee solid;
}


.leftImg {
}
.wh_detail {
    text-align: left;
}
.wh_detail > a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
}
.wh_detail > a p {
    margin: 0;
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
}
.QRIMg {
    position: relative;
    padding: 0 40px;
}
.leftImg img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 4px;
    border: 2px solid #585858;
}

.image--container {
    background-color: #f8f8f8;
    border: solid 1px #eee;
    margin-bottom: 50px;
    padding-top: 18px;
    border-radius: 10px;
}
.btn-outer {
    position: absolute;
    top: 0px !important;
    left: 0;
    right: 0;
    display: inline-flex;
    margin: 0px;
    box-shadow: 0 4px 12px rgba(0,0,0,.35);
    border-radius: 5px;
    justify-content: center;
}
.btn-outer.singlebtn {
    box-shadow: 0 4px 12px rgba(0,0,0,0);
}
.btn-outer.singlebtn .zoom-btn {
    border-radius: 5px;
}
.btn-outers {
    display: inline-flex;
    box-shadow: 0 4px 12px rgba(0,0,0,.35);
    border-radius: 5px;
    width: 100%;
    justify-content: flex-end;
}
.whList .btn-outers 
{
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 0;
    width: calc(100% - 40px);  
}
strong.qrcode_show {
    font-size: 23px;
    margin-bottom: 30px;
    display: inline-flex;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    flex: 1;
    width: 100%;
    justify-content: center;
}
strong.qrcode_showNew {
    font-size: 23px;
    margin-bottom: 2px;
    display: inline-flex;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    flex: 1;
    width: 100%;
    justify-content: center;
}
.zoom-btn {
    background: rgba(88, 88, 88, .88);
    font-weight: 400;
    font-size: 14px;
    transition: all .2s ease;
    padding: 10px 3px;
    color: #fff;
    flex: 0 0 50%;
    border-radius: 0 5px 5px 0;
}
.view--more-btn {
    background-color: rgba(88, 88, 88, .88);
    padding: 10px;
    flex: 0 0 50%;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    border-right: 1px solid #858585;
    border-radius: 5px 0 0 5px;
}
.view--more-btn.child--view-btn {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: calc(50% - 19px);
    z-index: 1;
}
.child--card-whlist {
    position: relative;
}
.child--card-whlist > a {
    position: relative;
    width: 100%;
    display: block;
}
.zoom-btn:hover, .view--more-btn:hover {
    background-color: rgb(185 122 86);
    color: #fff;
}
.list-text h6 {
    font-size: 12px;
    color: #777;
    margin-bottom: 15px;
    margin-top: 10px;
}

.nav-ul 
{
	position: absolute;
    left: 0;
	display:none;
    right: 0;
	padding: 0;
    background: #3a3a47;
    top: 100%;
}
.nav-ul a 
{
	padding: 10px 10px;
	display: inline-block;
	color: #fff;
	border-bottom: 1px solid #fff;
	width: 100%;
}
.modal-backdrop {
    opacity: .7 !important;
}
#zoom-btn .modal-body {
    padding: 10px;
    display: flex;
    align-items: center;
}
#zoom-btn button.btn-closee {
    background: transparent;
    position: absolute;
    right: 15px;
    z-index: 9;
    top: 15px;
    background-color: rgba(0,0,0,.5);
    padding: 2px;
    border-radius: 50%;
}
/* #zoom-btn .modal-body .QRImg {
    max-width: 700px;
} */
#zoom-btn .modal-body .QRImg {
    max-width: 100%;
    background-color: #eee;
    text-align: center;
    width: auto;
}
.header-section .dropdown-toggle::after {
	display: none;
}
.header-section .dropdown-item {
	padding: 0.75rem 1rem;
}
#zoom-btn .QRImg {
    margin: 0 auto;
}
#zoom-btn  .modal-content {
    background: transparent;
    border: navajowhite;
}

.lists {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 14px;
    max-width: 720px;
    width: 100%;
    margin: 0 auto 20px;
    text-align: left;
	cursor:pointer;
	color: #000000;
    border: solid 1px #eee;
}
.list-cards {
    padding-bottom: 60px;
}

    .lists:hover {
        box-shadow: 0px 8px 25px rgb(0 0 0 / 23%);
    }

    .list-img {
        width: 200px;
        height: 150px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        margin-right: 40px;
        transition: all 0.3s ease-in-out 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EBEBEF;
    }
       

.list-text {
    flex: 1 1;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}

        .list-text p {
            font-weight: 400;
            margin-top: 10px;
            font-size: 16px;
            line-height: 22px;
            margin-top: 0;
        }

    .objectFit {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .overlay-sec {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.39);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .QR-detail {
        box-shadow: 0px 6px 30px rgb(0 0 0 / 8%);
        border-radius: 10px;
        padding: 25px;
        text-align: left;
        background: #fff;
        max-width: 900px;
        width:100%;
        margin: auto;
        display: inline-flex;
        flex-direction:column;
        gap:20px;
    }

    .qrImgtext {
        flex: 1;
    }

   /* .qrImgbox {
        max-width: 300px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 60px;
        max-height: 250px;
        min-width: 300px;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 15px
    }*/
.qrImgbox {
    /* max-width: 300px; */
    border-radius: 10px;
    overflow: hidden;
    /* margin-right: 60px; */
    height: 400px;
    min-width: 300px; 
    width:100%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px;
}
    .qrImgbox img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius:10px;
    }
    .view-option ul li {
        background: #f1f1f1;
        padding: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .view-option ul li.active {
        background: #B97A56;
    }
    .no-shadow .btn-outer {
    box-shadow: none;
}

    .qrImgbox .svg {
        max-width: 60px;
    }

.qrImgtext h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;
}

    .info-text {
        margin-bottom: 15px;
    }

    .qrImgtext strong {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 10px;
        display: inline-block;
    }

    .qrImgtext p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #454545;
        margin: 0;
    }

    .qrImgtext b {
        width: 100%;
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    .qrImgtext span {
        font-weight: 400;
        /*color: #727279;*/
    }
    /*.qrImgbox img 
{
	height:100%;
	width:100%;
	object-fit:cover;
}*/
    .backBtn a {
        width: 33px;
        height: 35px;
        display: inline-flex;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        /* margin-right: 26px; */
        margin-left: -10px;
    }

        .backBtn a img {
            height: 20px;
            margin-left: -2px;
        }

    .zoom-detail-page.btn-outer {
        top: 0;
        height: 100%;
        display: flex;
    }

        .zoom-detail-page.btn-outer .zoom-btn {
            text-indent: -99999px;
            background-color: transparent;
            width: 100%;
            height: 100%;
            flex: 0 0 100%;
        }
    /*.thumb--inner {
    max-width: 40px;
    padding: 21px 0;
    object-fit: fill !important;
    margin-left: 43px;
}*/
    .thumb--inner-new {
        max-width: 40px;
        padding: 21px 0;
        object-fit: fill !important;
        margin-left: 13px;
    }

    .pdf-download-btn {
        flex: 1 0 100%;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        color: #333;
        height: 57px;
        padding-left: 10px;
        align-items: center;
        border: solid 1px #ddd;
        border-radius: 6px;
        background-color: #e4e7e9;
        margin: 0 0 15px;
    }

        .pdf-download-btn > div {
            display: flex;
            align-items: center;
            column-gap: 15px;
        }

            .pdf-download-btn > div > b {
                font-weight: 500;
                font-size: 12px;
                color: #777;
                margin-top: 2px;
            }

        .pdf-download-btn > img {
            padding: 10px 0;
        }

        .pdf-download-btn span {
            border-left: 1px solid #D1D5DB;
            height: 100%;
            display: flex;
            padding: 15px;
        }

            .pdf-download-btn span > img {
                width: 19px;
                margin: 0 auto;
            }

    .hamburger {
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
    }

        .hamburger:hover {
            opacity: 0.7;
        }

        .hamburger.is-active:hover {
            opacity: 0.7;
        }

        .hamburger.is-active .hamburger-inner,
        .hamburger.is-active .hamburger-inner::before,
        .hamburger.is-active .hamburger-inner::after {
            background-color: #000;
        }

    .hamburger-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
    }

        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            width: 40px;
            height: 4px;
            background-color: #000;
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

            .hamburger-inner::before, .hamburger-inner::after {
                content: "";
                display: block;
            }

            .hamburger-inner::before {
                top: -10px;
            }

            .hamburger-inner::after {
                bottom: -10px;
            }

    .hamburger--collapse .hamburger-inner {
        top: auto;
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

        .hamburger--collapse .hamburger-inner::after {
            top: -20px;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
        }

        .hamburger--collapse .hamburger-inner::before {
            transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

    .hamburger--collapse.is-active .hamburger-inner {
        transform: translate3d(0, -10px, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

        .hamburger--collapse.is-active .hamburger-inner::after {
            top: 0;
            opacity: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
        }

        .hamburger--collapse.is-active .hamburger-inner::before {
            top: 0;
            transform: rotate(-90deg);
            transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

    .logo img {
        height: 30px;
    }


    @media screen and (max-width:767px) {
        .list-text h1 {
            font-size: 20px !important;
        }

        .wkbtn {
            font-size: 12px !important;
        }

        .image--container {
            margin-bottom: 25px;
        }

        .btn-outers button {
            font-size: 13px;
        }

        .pdf-download-btn {
            margin: 0 15px;
        }

        .list-text {
            padding-top: 0;
        }

        .QR-detail {
            /*flex-wrap: wrap;*/
            gap:10px;
        }


        .Qr-detail {
            padding: 11px 0;
            margin-bottom: 25px;
        }

        .qrImgbox {
            margin-right: 0;
            width: 100%;
            /* max-height: 100%; */
            margin-bottom: 0px;
            position: relative;
            height: calc(100vh - 215px);
        }

        .qrImgtext h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            margin-bottom: 10px;
            line-height: 18px;
        }
        .info-text{
            margin-bottom:10px;
        }

        .qrImgtext strong {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        .list-img {
            width: 40%;
            height: 115px;
            overflow: hidden;
            border-radius: 6px;
            margin-right: 15px;
        }

        .QR-detail {
            padding: 11px;
        }

        .list-text p {
            font-weight: 400;
            margin-top: 0;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
            margin-top: -6px;
        }

        button.btn-closee img {
            height: 20px;
        }

        .QrCode {
            font-size: 18px;
            min-width: inherit;
            padding: 9px 20px 9px 20px;
        }

        .copy-btn {
            right: 10px;
        }

        .Qr-detail h3 {
            margin-right: 10px;
            margin-bottom: 0px;
        }

        .center-section {
            background-position: 30px -20px;
            padding-bottom: 0;
        }

        .container-fluid {
            padding: 0 15px;
        }

        .btn-primary {
            padding: 0 15px;
        }

        .logo img {
            height: 17px;
        }

        .QRLeft {
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
        }

        .main-heading {
            font-size: 22px;
            line-height: 32px;
        }

        .small-heading {
            font-size: 21px;
        }

        .QRInner::after {
            left: 7px;
            top: 7px;
        }

        .QrRight {
            width: 100%;
        }

            .QrRight h4 {
                font-size: 20px;
                line-height: 25px;
            }

            .QrRight img {
                height: 70px;
                margin-right: 20px;
            }

            .QrRight p {
                font-size: 15px;
                line-height: 20px
            }

        .footer {
            text-align: center;
        }

            .footer .row {
                row-gap: 8px;
            }

                .footer .row div {
                    text-align: center !important;
                }

        .col-sm-6.text-end {
           /* margin-top: 10px;*/
            text-align: center !important;
        }

        .overlay-sec img {
            height: 25px;
        }

        .center-section {
            min-height: calc(100vh - 152px);
        }
    }

    .QR-child-img {
        justify-content: space-between;
        margin-top: 20px;
        display: flex;
        border: solid 1px #E6ECEE;
        background-color: #F1F4F5;
        border-radius: 10px;
        padding: 10px;
    }

        .QR-child-img a {
            display: inline-block;
            overflow: hidden;
            border-radius: 6px;
            border: solid 2px transparent;
            display: flex;
            flex-direction: column;
        }

    .QR-child-img_inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .QR-child-img a.parent--img img {
        width: 85px;
        height: 85px;
    }

    .QR-child-img_wrap a span {
        color: #333;
        font-size: 12px;
        display: block;
        margin-top: 5px;
    }

    .QR-child-img_wrap a:hover span {
        color: #b97a56;
    }

    .QR-child-img a.child--img > img {
        width: 85px;
        height: 85px;
    }

    .QR-child-img a:hover, .QR-child-img a:active, .QR-child-img a:focus {
        border-color: #b97a56;
    }

    .QR-child-img a img {
        height: auto;
        max-width: 100%;
        border-radius: 6px;
        object-fit: cover;
        border: solid 1px #e3e3e3;
    }

    .list-text h1 {
        font-size: 30px;
        position: relative;
        margin-bottom: 15px;
        font-weight: bold;
        color: #585858;
        padding-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

        .list-text h1::after {
            width: 80px;
            height: 2px;
            background: #b97a56;
            content: '';
            left: 0;
            right: 0;
            position: absolute;
            bottom: 0;
            margin: auto;
        }

    .list-text h3 {
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        margin-bottom: 15px;
        font-weight: bold;
        color: #585858;
        padding-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .comingSoon {
        height: 100vh;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .comingSoon img {
            max-height: 100%;
        }


    .breadcrumb {
        background: transparent;
        max-width: 520px;
        margin: 0 auto 0 !important;
    }

        .breadcrumb > span {
            position: relative;
            padding: 0;
            margin: 4px 4px 4px 0;
            display: inline-block;
            float: left;
        }

            .breadcrumb > span .ant-breadcrumb-link {
                position: relative;
                padding: 0.4em 0.8em 0.3em 1.3em;
                display: inline-block;
                color: #2c3f4c;
                background-color: #ededed;
                border-color: #ebebeb;
            }

                .breadcrumb > span .ant-breadcrumb-link a {
                    color: #525252;
                    font-size: 12px;
                }

        .breadcrumb .ant-breadcrumb-separator {
            display: none;
        }

        .breadcrumb > span:after, .breadcrumb > span .ant-breadcrumb-link::after {
            /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            content: '';
            /* 48px is the height of the <a> element */
            border: 15px solid transparent;
            border-right-width: 0;
            border-left-width: 9px;
        }

        .breadcrumb > span::after {
            /* this is the white separator between two items */
            z-index: 1;
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
            border-left-color: #ffffff;
            /* reset style */
            margin: 0;
        }

        .breadcrumb > span:first-child .ant-breadcrumb-link {
            padding-left: 10px;
            padding-right: 5px;
        }

        .breadcrumb > span .ant-breadcrumb-link::after {
            /* this is the colored triangle after each element */
            z-index: 2;
            border-left-color: inherit;
        }

        .breadcrumb > span:last-child::after, .breadcrumb > span:last-child .ant-breadcrumb-link::after {
            /* hide the triangle after the last step */
            display: none;
        }

        .breadcrumb > span .ant-breadcrumb-link:hover::after {
            border-left-color: #d5d5d5;
        }

        .breadcrumb > span .ant-breadcrumb-link:hover {
            background: #d5d5d5;
        }

            .breadcrumb > span .ant-breadcrumb-link:hover a {
                color: #fff;
            }

        .breadcrumb > span:last-child .ant-breadcrumb-link::after {
            border-left-color: #d5d5d5;
        }

        .breadcrumb > span:last-child .ant-breadcrumb-link {
            background: #d5d5d5;
        }

            .breadcrumb > span:last-child .ant-breadcrumb-link a {
                color: #b97a56;
                font-weight: bold;
            }

            .breadcrumb > span:last-child .ant-breadcrumb-link::after {
                display: none;
            }

        .breadcrumb > span:last-child .ant-breadcrumb-link {
            border-radius: 0 .25em .25em 0;
        }

    .invalid-QR {
        display: inline-flex;
        min-height: calc(100vh - 127px);
        align-items: center;
        padding: 30px;
        justify-content: center;
        width: 100%;
        text-align: center;
        background: #fff;
    }

    a.home-icon {
        background: #eee;
        display: inline-flex;
        width: 75px;
        height: 75px;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 567px) {
        .center-text {
            text-align: center !important;
        }
    }

    .qr_title-main {
        font-size: 25px;
        margin: 16px 0 10px;
        display: block;
        padding: 0 10px;
        line-height: 28px;
        text-align: left;
        color: #000;
        font-weight: 400;
    }

    .QRIMg.qr--container {
        padding: 0 10px;
    }

    .qr-main_code {
        display: block;
        font-size: 15px;
        color: #b97a56;
        position: relative;
        left: 0;
        margin-bottom: 20px;
        text-align: left;
    }

    .qr-main_code-inner {
        padding: 0px 10px 10px;
        display: inline-block;
        font-weight: 600;
    }

    .gap-15-btns {
        display: flex;
        column-gap: 15px;
    }

    @media screen and (max-width: 560px) {
        button.searchButton {
            margin-right: 15px
        }
    }
.modal-dialog.modal-dialog-zoom {
    width: calc(100% - 15px) !important;
}
.modal-dialog-zoom.childmodal-zoom .modal-header {
    padding: 0;
    border: 0 !Important
}
    .modal-dialog-zoom.childmodal-zoom .modal-header .btn-close {
        position: absolute;
        top: 13px;
        right: 16px;
        z-index: 1;
        background-color: #ffffffc4;
        border-radius: 50%;
        opacity: 1;
    }
.modal-dialog-zoom.childmodal-zoom .modal-body {
    padding: 0;
}
.modal-dialog-zoom.childmodal-zoom .modal-content {
    border-radius: 16px;
    overflow: hidden;
    border: solid 4px rgb(0 0 0 / 30%);
}
#collapseSix .fileContainer .errorsContainer {
    max-width: 100%;
    font-size: 12px;
    color: red;
    text-align: left;
    position: absolute;
    left: 0;
    top: -18px;
}
.ErrorMsg
{
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
}
.list-text-wrap strong {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

    .list-text-wrap strong:last-child {
        margin: 0;
    }

.uploadMedia .modal-content {
    margin-top: 20px !important;
}
.inner-section {
    min-height: calc(100vh - 127px);
    padding: 30px 0;
   
}
.about-us 
{
    text-align: center;
}
    .inner-section h3 {
        font-weight: 600;
        margin-bottom: 20px;
        color: #b97a57;
        font-size: 25px;
        text-align: center;
    }
.inner-section p {
    color: #777;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
}
.inner-section .card-box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 60px 15px;
    min-height: calc(100vh - 187px);
}
.overlay-section {
    padding: 20px;
    width: 100%;
    border-radius: 10px;
}

.map {
    position: relative;
}
.overlay-section h5 {
    margin-bottom: 20px;
    color: #b97a57;
}
.overlay-section span {
    font-size: 12px;
    color: #b97a57;
}
.overlay-section h6 {
    font-size: 16px;
    margin-bottom: 25px;
}
.react-pdf__Document {
    width: 100%;
    max-width: 550px;
    margin: auto;
    max-height: calc(100vh - 220px);
    overflow: hidden;
    overflow-y: scroll;
}
    .react-pdf__Document canvas {
        width: 100% !important;
        margin-bottom: 15px;
        height: auto !important;
    }
.pdf-download-btn1 img {
    filter: brightness(0) invert(1);
    height: 15px;
}
.pdf-outer
{
    max-width: 550px;
    margin: auto;
    position: relative;
}
a.pdf-download-btn1 {
    position: absolute;
    right: 15px;
    z-index: 9;
    background: #b97a56;
    top: 10px;
    display: inline-block;
    padding: 7px 10px;
    border-radius: 4px;
}

.virtual {
    z-index: 9;
   /* background: #b97a56;*/
    top: 10px;
    display: inline-flex !important;
    padding: 7px 10px;
    border-radius: 4px;
}

.custom-header {
    font-weight: bold !important;
    font-size: 18px !important;
    color: #ff0000 !important;
    text-align: left !important;
}
    @media screen and (max-width:1200px) {
        .modal-dialog.modal-dialog-media.uploadMedia {
        width: 100% !important;
        max-width: 1000px !important;
        margin: 0;
    }
    .uploadMedia  .modal-content {
        margin-top: 20px !important;
    }
}
@media screen and (max-width:1023px)
{
    .mobile_pdf {
        display: none;
    }

   /* .pdf-containernew iframe{
        width:100% !important;
         
    }*/
    .wk-column {
        width: 50% !important;
    }
}

@media screen and (min-width:1025px) {
    .mobile_pdf {
        display: none;
    }
}

.mobile_pdf{
    display: block;
}


@media screen and (max-width:767px) {
    .wk-column {
        width: 100% !important;
    }
    .mt20
    {
        margin-top: 10px;
    }
}

.wkHtml {
    height: calc(100% - 20px);
}

.header-nav .dropdown .dropdown-item.active, .header-nav .dropdown .dropdown-item:active {
    background-color: #b97a56 !important;
}

.uploadPicturesWrapper div {
    justify-content: flex-start !important;
    gap: 10px !important;
}


/*.pdfview{
    position:relative; // to make pop out in pdf unclickable
}
.pdfview:before {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    right: 24px;
    top: 5px;
}*/

.rdw-link-modal-btn {
    width: auto;
    padding: 5px 15px !important;
}
.rdw-link-modal
{
    height: auto !important;
}

.qr-container {
    margin-bottom: 0px; /* Adjust the margin to create space 17-12-24 */
}
